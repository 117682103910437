$(window).on('load', function() {
    $("body").removeClass("js-load-before").addClass("js-load-after");
});

$(document).ready(function () {

  // Smoothscroll
  (function() {
  	if ($(".js_smoothscroll").length) {
  		$(".js_smoothscroll a").on("click", function(e) {
  			e.preventDefault();
  			var target = this.hash,
  			$target = $(target);
  			$("html, body").stop().animate({
  				scrollTop: $target.offset().top
  			}, 800, "swing", function() {
  				window.location.hash = target;
  			});
  		});
  	}
  })();

  // Menu
  (function() {
    $(".menu-icon").on("click", function(e) {
      $('body').toggleClass('js-menu-opened');
      $(this).toggleClass('js-menu-opened');
      $("#menu-navigation-principale").toggleClass('js-menu-opened');
      $("#menu-haut-de-page").toggleClass('js-menu-opened');
    });
  })();

  // Visibility
  (function() {

    var e_window = $(window);
    var windowHeight = e_window.innerHeight();
    var securitySpace = 100;
    var items = $(".js-visibility");

    function checkVisibility() {
      if (items.length > 0) {
        $(items).each(function() {
          if (
            this.getBoundingClientRect().top >= windowHeight - securitySpace ||
            this.getBoundingClientRect().bottom <= securitySpace
          ) {
            // Not visible on screen
            $(this).addClass(":offscreen");
            // remove this line if you only want 1 direction
            //$(this).removeClass(":onscreen");
          } else {
            // Visible on screen
            $(this).removeClass(":offscreen").addClass(":onscreen");
          }
        });
        requestAnimationFrame(checkVisibility);
      }
    }
    requestAnimationFrame(checkVisibility);
  })();

  // Popup load
  (function() {
    if ($(".js-popup").length) {
      $(".js-popup").on("click", function(e) {
        e.preventDefault();
        var target = $(this).attr('href');
        $('.popup-load').empty();
        if ($('.popup').hasClass('popup-hidden')) {
          $('.popup').removeClass('popup-hidden').addClass('popup-visible');
          $('.popup-load').load( target + " #popupcontent", function() {
            $(".swiper-default").each(function(index, element){
                var container = $(this).find('.swiper-container');
                swiper = new Swiper(container, {
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    centeredSlides: true,
                    spaceBetween: 0,
                    autoHeight: true,
                    loop: true,
                    keyboard: true
                });
            });
          });
        } else {
          $('.popup').removeClass('popup-visible').addClass('popup-hidden');
          $('.popup-load').empty();
        }
      });
    }
  })();

  // Popup close
  (function() {
    if ($(".popup-close").length) {
      $(".popup-close").on("click", function(e) {
        $('.popup').removeClass('popup-visible').addClass('popup-hidden');
        $('.popup-load').empty();
      });
    }
  })();


});
